import { baseApi } from 'store/store';

export const convertersApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    convertWordToHtml: builder.mutation({
      query: (data) => ({
        url: `/converters/doctohtml`,
        method: 'POST',
        body: data,
      }),
    }),
    convertPdfToDoc: builder.mutation({
      query: (data) => ({
        url: `/converters/pdftodoc`,
        method: 'POST',
        body: data,
        responseHandler: (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
    }),
  })
});

export const {
  useConvertWordToHtmlMutation,
  useConvertPdfToDocMutation,
} = convertersApi;
