import { baseApi } from 'store/store';

export const templatesApi = baseApi
.enhanceEndpoints({
  addTagTypes: [
    'Templates', 'TemplatesContent', 'TemplatesPreviews', 'TemplatesAttachments',
    'TemplatesCategories', 'TemplatesCategoriesTree', 'Folders', 'FoldersTree'
  ]
})
.injectEndpoints({
  endpoints: builder => ({
    getSharedTemplates: builder.query({
      query: params => ({
        url: '/sharedtemplatesadmin/',
        method: 'GET',
        params: params
      }),
      providesTags: ['Templates']
    }),
    addSharedTemplate: builder.mutation({
      query: data => ({
        url: '/sharedtemplatesadmin/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Templates', 'FoldersTree']
    }),
    importSharedTemplate: builder.mutation({
      query: data => ({
        url: '/sharedtemplatesadmin/doc',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Templates', 'FoldersTree']
    }),
    getSharedTemplate: builder.query({
      query: id => ({
        url: `/sharedtemplates/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Templates', id: id }]
    }),
    getSharedTemplateAdmin: builder.query({
      query: id => ({
        url: `/sharedtemplatesadmin/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Templates', id: id }]
    }),
    saveSharedTemplate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedtemplatesadmin/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Templates', 'FoldersTree']
    }),
    removeSharedTemplate: builder.mutation({
      query: id => ({
        url: `/sharedtemplatesadmin/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Templates', 'FoldersTree']
    }),
    copySharedTemplate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedtemplatesadmin/${id}/copy`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Templates', 'FoldersTree']
    }),
    getSharedTemplateContent: builder.query({
      query: id => ({
        url: `/sharedtemplates/${id}/content`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'TemplatesContent', id: id }]
    }),
    saveSharedTemplateContent: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedtemplatesadmin/${id}/content`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TemplatesContent', id: id }]
    }),
    renderSharedTemplate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedtemplatesadmin/${id}/render`,
        method: 'POST',
        body: data,
        responseHandler: async (response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        },
      }),
      invalidatesTags: ['Counteragents']
    }),

    getSharedTemplatePreviews: builder.query({
      query: id => ({
        url: `/sharedtemplates/${id}/previews`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'TemplatesPreviews', id: id }]
    }),
    addSharedTemplatePreview: builder.mutation({
      query: ({ id, body }) => ({
        url: `/sharedtemplatesadmin/${id}/previews`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TemplatesPreviews', id: id }]
    }),
    sortSharedTemplatePreviews: builder.mutation({
      query: data => ({
        url: `/sharedtemplatesadmin/previews`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => ['TemplatesPreviews']
    }),
    removeSharedTemplatePreview: builder.mutation({
      query: ({ id, previewid }) => ({
        url: `/sharedtemplatesadmin/${id}/previews/${previewid}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TemplatesPreviews', id: id }]
    }),

    getSharedTemplateAttachments: builder.query({
      query: id => ({
        url: `/sharedtemplates/${id}/attachments`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'TemplatesAttachments', id: id }]
    }),
    addSharedTemplateAttachment: builder.mutation({
      query: ({ id, body }) => ({
        url: `/sharedtemplatesadmin/${id}/attachments`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TemplatesAttachments', id: id }]
    }),
    removeSharedTemplateAttachment: builder.mutation({
      query: ({ id, attachmentid }) => ({
        url: `/sharedtemplatesadmin/${id}/attachments/${attachmentid}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'TemplatesAttachments', id: id }]
    }),

    getSharedTemplatesCategories: builder.query({
      query: () => ({
        url: `/sharedtemplates/categories`,
        method: 'GET',
      }),
      providesTags: ['TemplatesCategories']
    }),
    getSharedTemplatesCategoriesTree: builder.query({
      query: () => ({
        url: `/sharedtemplates/categories/all`,
        method: 'GET',
      }),
      providesTags: ['TemplatesCategoriesTree']
    }),
    addSharedTemplatesCategory: builder.mutation({
      query: data => ({
        url: `/sharedtemplatesadmin/categories`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TemplatesCategories', 'TemplatesCategoriesTree']
    }),
    saveSharedTemplatesCategory: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedtemplatesadmin/categories/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['TemplatesCategories', 'TemplatesCategoriesTree']
    }),
    removeSharedTemplatesCategory: builder.mutation({
      query: id => ({
        url: `/sharedtemplatesadmin/categories/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TemplatesCategories', 'TemplatesCategoriesTree']
    }),

    getSharedFoldersTree: builder.query({
      query: () => ({
        url: '/sharedfoldersadmin/all',
        method: 'GET',
      }),
      providesTags: ['FoldersTree']
    }),
    getSharedFolders: builder.query({
      query: () => ({
        url: '/sharedfoldersadmin/',
        method: 'GET',
      }),
      providesTags: ['Folders']
    }),
    getSharedFoldersById: builder.query({
      query: id => ({
        url: `/sharedfoldersadmin/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Folders', id: id }]
    }),
    addSharedFolder: builder.mutation({
      query: data => ({
        url: '/sharedfoldersadmin/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Folders', 'FoldersTree']
    }),
    saveSharedFolder: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/sharedfoldersadmin/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Folders', 'FoldersTree']
    }),
    removeSharedFolder: builder.mutation({
      query: id => ({
        url: `/sharedfoldersadmin/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Folders', 'FoldersTree']
    }),
  })
});

export const {
  useGetSharedTemplatesQuery,
  useAddSharedTemplateMutation,
  useImportSharedTemplateMutation,
  useGetSharedTemplateQuery,
  useGetSharedTemplateAdminQuery,
  useSaveSharedTemplateMutation,
  useRemoveSharedTemplateMutation,
  useCopySharedTemplateMutation,
  useGetSharedTemplateContentQuery,
  useSaveSharedTemplateContentMutation,
  useRenderSharedTemplateMutation,

  useGetSharedTemplatePreviewsQuery,
  useAddSharedTemplatePreviewMutation,
  useSaveSharedTemplatesCategoryMutation,
  useRemoveSharedTemplatePreviewMutation,
  useSortSharedTemplatePreviewsMutation,

  useGetSharedTemplateAttachmentsQuery,
  useAddSharedTemplateAttachmentMutation,
  useRemoveSharedTemplateAttachmentMutation,

  useGetSharedTemplatesCategoriesQuery,
  useGetSharedTemplatesCategoriesTreeQuery,
  useAddSharedTemplatesCategoryMutation,
  useRemoveSharedTemplatesCategoryMutation,

  useGetSharedFoldersTreeQuery,
  useGetSharedFoldersQuery,
  useGetSharedFoldersByIdQuery,
  useAddSharedFolderMutation,
  useSaveSharedFolderMutation,
  useRemoveSharedFolderMutation,
} = templatesApi;
