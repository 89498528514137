import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import { FileDropzone, LoadingIndicator } from '@forma/forma-ui-kit';
import { useConvertPdfToDocMutation } from 'store/converters/convertersApi';

import styles from './Converters.module.css';

const Converters = () => {
  const { t } = useTranslation();
  const [ convertPdfToDoc, { isFetching, isLoading } ] = useConvertPdfToDocMutation();

  return (
    <AdminLayout
      title="Конвертеры"
    >
      <PageTitle>{t('site_name') + ' – Конвертеры'}</PageTitle>

      <div>
        <h3>Конвертер PDF в DOC</h3>
        <div className={styles.dropzone}>
          <FileDropzone
            maxFiles={1}
            onChange={(files) => {
              if (!files.length) return;
              const nameParts = files[0].name.split('.');
              const formData = new FormData();
              formData.append('file', files[0]);

              convertPdfToDoc(formData)
                .unwrap()
                .then((url) => {
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.download = nameParts[0] + '.doc';
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
            }}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            showAccept
          />
          {(isFetching || isLoading) && (
            <div className={styles.loader}>
              <LoadingIndicator />
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Converters;