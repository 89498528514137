import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal, Tooltip } from '@forma/forma-ui-kit';
import WorkspaceBlock from '../WorkspaceBlock';

import styles from './workspace-entities.module.css';

const WorkspaceEntities = ({ id, items, onSubmit }) => {
  const { t } = useTranslation();
  const [ organizationForApprove, setOrganizationForApprove ] = useState(null);

  return (
    <WorkspaceBlock title="Организации для заполнения">
      <div className={styles.items}>
        {items?.map(item => {
          const { id, inn, kpp, name, address, email, approved } = item;
          // todo: change to universal format phone
          const x = (item.phone && item.phone.length >= 11) && item.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
          const phone = x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '';

          return (
            <div className={styles.row} key={id}>
              <div className={styles.icon}>
                <Tooltip
                  control={
                    <div onClick={() => setOrganizationForApprove(item)}>
                      <Checkbox id={`approved-${id}`} name={`approved-${id}`} checked={approved} readOnly />
                    </div>
                  }
                >
                  {approved ? 'Отменить подтверждение' : 'Подтвердить'}
                </Tooltip>
              </div>
              <div className={styles.left}>
                <div className={styles.date}>ИНН: {inn}</div>
                <div className={styles.date}>КПП: {kpp}</div>
              </div>
              <div className={styles.right}>
                <div className={styles.content}>Организация: {name}</div>
                <div className={styles.content}>Адрес: {address}</div>
                <div className={styles.content}>Email: {email}, Телефон: {phone}</div>
                <div className={styles.content}>{approved}</div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        open={!!organizationForApprove}
        onClose={() => setOrganizationForApprove(null)}
        title="Подтверждение организации"
        buttons={[
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          },
          {
            children: t('submit'),
            viewStyle: 'primary',
            onClick: () => onSubmit({ wid: id, id: organizationForApprove?.id, approved: !organizationForApprove?.approved })
          },
        ]}
      >
        <div className={styles.modalContent} style={{ textAlign: 'center' }}>
          {organizationForApprove?.approved
            ? "Вы уверены что хотите отменить подтверждение организации?"
            : "Вы уверены что хотите подтвердить организацию?"
          }
        </div>
      </Modal>
    </WorkspaceBlock>
  );
};

export default WorkspaceEntities;
